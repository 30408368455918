import { useCookies } from "react-cookie";
import { FaCartArrowDown } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
interface props {
  // img: any;
  // title: string;
  // description: string;
  // price: number;
  // discount: number;
  data: {
    id: number;
    images: {
      src: any;
      alt: string;
    }[];
    title: string;
    description: string;
    sizes: string[];
    styles: string[];
    price: number;
    discountPercentage: number;
    category: string;
  };
}
export default function Card({
  data,
}: // img,
// title,
// description,
// price,
// discount,
props) {
  const [cookies, setCookie] = useCookies(["cart"]);
  const navigate = useNavigate();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });
  // const { cart, setCart } = useCart();
  return (
    <div
      className="card"
      style={{ minWidth: "300px", maxWidth: "300px" }}
      key={data.id}
    >
      <Link to={`/productDetails/${data.id}`}>
        <img
          src={data.images[0].src}
          className="card-img-top"
          style={{ width: "300px", height: "320px", objectFit: "cover" }}
          alt="..."
        />
      </Link>

      <div className="card-body" style={{ padding: "0" }}>
        <h5 className="card-title" style={{ padding: "10px 16px 0 16px" }}>
          {data.title}
        </h5>
        
        <div
          style={{
            width: "100%",
            // backgroundColor: "red",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
            marginBottom: "10px",
          }}
        >
          {data.discountPercentage > 0 ? (
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ textDecoration: "line-through" }}>
                {formatter.format(data.price)}
              </span>
              <span
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  marginTop: "-10px",
                }}
              >
                {/* Rs. */}
                {formatter.format(
                  Math.round(
                    data.price - (data.price * data.discountPercentage) / 100
                  )
                )}
              </span>
            </span>
          ) : (
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              {/* Rs. */}
              {formatter.format(data.price)}
            </span>
          )}
          <Link
            to={`/productDetails/${data.id}`}
            className="addToCartBtn btn btn-dark d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "0",
              width: "140px",
              height: "40px",
              border: "2px solid #212529",
            }}
            // onClick={() => {
            //   if (cookies.cart) {
            //     setCookie("cart", [...cookies.cart, { ...data, quantity: 1 }]);
            //   } else {
            //     setCookie("cart", [
            //       {
            //         ...data,
            //         quantity: 1,
            //       },
            //     ]);
            //   }
            //   console.log({
            //     name: data.title,
            //     price:
            //       data.discountPercentage > 0
            //         ? Math.round(
            //             data.price -
            //               (data.price * data.discountPercentage) / 100
            //           )
            //         : data.price,
            //   });
            // }}
          >
            {/* <IoAdd style={{ fontSize: "1.4rem", marginRight: "3px" }}></IoAdd> */}
            Buy
          </Link>
        </div>
      </div>
    </div>
  );
}
