import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { IoAdd, IoPricetag } from "react-icons/io5";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { GiShoppingBag } from "react-icons/gi";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";

export default function ProductDetails() {
  const [cookies, setCookie] = useCookies(["cart"]);
  const { id } = useParams();
  const [data, setData] = useState<
    | {
        id: number;
        images: { src: string; alt: string }[];
        title: string;
        description: string;
        sizes: string[];
        styles: string[];
        price: number;
        discountPercentage: number;
        category: string;
        isOutOfStock: boolean;
        sizeChart: {
          src: string;
          alt: string;
        } | null;
      }
    | undefined
  >();
  const fetchProduct = async () => {
    const product = await axios.get(`${config.baseUrl}product/${id}`);
    setData(product.data);
  };
  useEffect(() => {
    fetchProduct();
  }, [id]);
  const [selectedSize, setSelectedSize] = useState<number | null>(null);
  const [selectedStyle, setSelectedStyle] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number>(1);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });

  return (
    <>
      <Navbar />
      {data && (
        <>
          <div
            className="productDetails-Container"
            key={data.id}
            style={{
              paddingTop: "5rem",
              paddingBottom: "5rem",
              paddingLeft: "16px",
              minHeight: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "start",
            }}
          >
            <div>
              <div className="productDetails-imageContainer">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide productDetails-imageCarousel"
                >
                  <div className="carousel-indicators">
                    {data.images.map((img, index) => (
                      <>
                        {img.src != null && (
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={`${index}`}
                            className={index === 0 ? "active" : ""}
                            aria-current="true"
                            aria-label={`Slide ${index + 1}`}
                            style={{
                              // backgroundColor: "black",
                              border: "0.5px solid black",
                            }}
                          ></button>
                        )}
                      </>
                    ))}
                    {/* <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button> */}

                    {/* Carousel buttons */}
                  </div>
                  <div className="carousel-inner">
                    {data.images.map((image, index) => (
                      <>
                        {image.src != null && (
                          <div
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                            key={`productDetailsImage-${index}`}
                          >
                            <img
                              src={image.src}
                              className="productDetails-image"
                              alt={image.alt}
                              // style={{ objectFit: "cover" }}
                              style={
                                data.category === "accessories"
                                  ? { objectFit: "cover" }
                                  : { objectFit: "contain" }
                              }
                            />
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                      style={{
                        backgroundColor: "black",
                        mixBlendMode: "difference",
                      }}
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                      style={{
                        backgroundColor: "black",
                        mixBlendMode: "difference",
                      }}
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              {data.sizeChart != null && (
                <div
                  className="productDetails-imageContainer"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="carousel slide productDetails-imageCarousel">
                    <>
                      {data.sizeChart.src.length > 0 &&
                        data.sizeChart.alt.length > 0 && (
                          <img
                            src={data.sizeChart.src}
                            className="productDetails-image"
                            alt={data.sizeChart.alt}
                            style={{ objectFit: "contain" }}
                          />
                        )}
                    </>
                  </div>
                </div>
              )}
            </div>
            <div className="productDetails-Content">
              <span style={{ fontSize: "2rem", fontWeight: "400" }}>
                {data.title}
              </span>
              <span style={{ fontSize: "1.5rem", marginTop: "-5px" }}>
                {data.discountPercentage > 0 ? (
                  <>
                    <span
                      style={{
                        textDecoration: "line-through",
                        marginRight: "1rem",
                        fontSize: "1.2rem",
                      }}
                    >
                      {formatter.format(data.price)}
                    </span>
                    <IoPricetag />
                    {formatter.format(
                      Math.round(
                        data.price -
                          (data.price * data.discountPercentage) / 100
                      )
                    )}
                  </>
                ) : (
                  <>Rs.{data.price}</>
                )}
              </span>
              {/* <p>{data.description}</p> */}
              {data.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  className="productDescription"
                >
                  {/* {data.description} */}
                </div>
              )}
              {data.isOutOfStock ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "end",
                    height: "50px",
                    marginTop: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      width: "150px",
                      // backgroundColor: "red",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1.4rem",
                      color: "red",
                      border: "2px dashed red",
                      // borderRadius: "10rem",
                    }}
                  >
                    Out Of Stock
                  </div>
                  <span
                    style={{
                      color: "red",
                      fontSize: "1.2rem",
                      marginTop: "4px",
                    }}
                  >
                    Come back later
                  </span>
                </div>
              ) : (
                <>
                  {data.sizes && data.sizes.length > 0 && (
                    <>
                      <div className="productDetails-SizeContainer">
                        <span>Sizes</span>
                        <div className="productDetails-SizeBtnContainer">
                          {data.sizes.map((size, index) => (
                            <button
                              key={`sizeBtn-${index}`}
                              className={`productDetails-SizeBtn ${
                                selectedSize === index
                                  ? "productDetails-SizeBtnSelected"
                                  : ""
                              }`}
                              onClick={() => setSelectedSize(index)}
                            >
                              {size}
                            </button>
                          ))}
                        </div>
                      </div>
                      <span
                        id="sizeError"
                        style={{ color: "red", visibility: "hidden" }}
                      >
                        Please select a size
                      </span>
                    </>
                  )}
                  {data.styles && data.styles.length > 0 && (
                    <>
                      <div className="productDetails-StylesContainer">
                        <span>Styles</span>
                        <div className="productDetails-StyleBtnContainer">
                          {data.styles.map((style, index) => (
                            <button
                              key={`styleBtn-${index}`}
                              className={`productDetails-StyleBtn ${
                                selectedStyle === index
                                  ? "productDetails-StyleBtnSelected"
                                  : ""
                              }`}
                              onClick={() => setSelectedStyle(index)}
                            >
                              {style}
                            </button>
                          ))}
                        </div>
                      </div>
                      <span
                        id="styleError"
                        style={{ color: "red", display: "none" }}
                      >
                        Please select a style
                      </span>
                    </>
                  )}
                  <div className="selectQuantity-Container">
                    <span>Quantity</span>
                    <div className="selectQuantity-Box">
                      <div
                        className="selectQuantity-SubtractBtn"
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                      >
                        <MdOutlineHorizontalRule />
                      </div>
                      <div className="selectQuantity-text">{quantity}</div>
                      <div
                        className="selectQuantity-AddBtn"
                        onClick={() => setQuantity(quantity + 1)}
                      >
                        <IoAdd />
                      </div>
                    </div>
                  </div>
                  <div className="productDetails-btnsContainer">
                    <button
                      className="productDetails-addToCart"
                      onClick={() => {
                        if (!selectedSize) {
                          const sizeError =
                            document.getElementById("sizeError");
                          if (sizeError) {
                            sizeError.style.visibility = "visible";
                          }
                        } else {
                          const sizeError =
                            document.getElementById("sizeError");
                          if (sizeError) {
                            sizeError.style.visibility = "hidden";
                          }
                        }
                        if (!selectedStyle) {
                          const styleError =
                            document.getElementById("styleError");
                          if (styleError) {
                            styleError.style.visibility = "visible";
                          }
                        } else {
                          const styleError =
                            document.getElementById("styleError");
                          if (styleError) {
                            styleError.style.visibility = "hidden";
                          }
                        }
                        if (cookies.cart) {
                          setCookie("cart", [
                            ...cookies.cart,
                            {
                              ...data,
                              quantity: quantity,
                              sizes: selectedSize,
                              styles: selectedStyle,
                            },
                          ]);
                        } else {
                          setCookie("cart", [
                            {
                              ...data,
                              quantity: quantity,
                              sizes: selectedSize,
                              styles: selectedStyle,
                            },
                          ]);
                        }
                      }}
                    >
                      <IoAdd
                        style={{ fontSize: "1.5rem", marginRight: "5px" }}
                      />{" "}
                      Add to Cart
                    </button>
                    {/* <button
                  className="productDetails-buyNow"
                  // onClick={() => {
                  //   console.log(cookies);
                  // }}
                >
                  <GiShoppingBag
                    style={{ fontSize: "1.5rem", marginRight: "5px" }}
                  />{" "}
                  Buy Now
                </button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      <Footer />
    </>
  );
}
