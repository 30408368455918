import { FaShoppingBag } from "react-icons/fa";
import { FaOpencart } from "react-icons/fa6";
import { IoBagHandleOutline } from "react-icons/io5";
import { GiShoppingBag } from "react-icons/gi";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [cookies, setCookie] = useCookies(["cart"]);
  const [cartCount, setCartCount] = useState(
    cookies.cart ? cookies.cart.length : 0
  );
  useEffect(() => {
    setCartCount(cookies.cart ? cookies.cart.length : 0);
  }, [cookies]);

  return (
    <>
      <nav
        className="navbar fixed-top navbar-expand-sm navbar-dark"
        data-aos="fade-down"
        style={{ backgroundColor: "var(--navBarBG)" }}
      >
        <div
          className="container"
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
            position: "relative",
          }}
        >
          <Link className="navbar-brand" to={"/"}>
            <img
              src={require("../assets/img/logo.png")}
              alt="logo"
              style={{ width: "70px" }}
            ></img>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ marginRight: "70px" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto nvContainer">
              {/* <form className="d-flex" style={{ alignItems: "center" }}>
                <input
                  className=" searchInput form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  style={{
                    borderRadius: "0",
                    paddingTop: "0",
                    paddingBottom: "0",
                    height: "35px",
                    width: "25vw",
                  }}
                />
              </form> */}
              <li className="nav-item">
                <Link className="nav-link" to={"/productCategories/t-shirts"}>
                  T-Shirts
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/productCategories/shirts"}>
                  Shirts
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/productCategories/shorts"}>
                  Shorts
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"/productCategories/accessories"}
                >
                  Accessories
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/orderDetails"}>
                  My Order
                </Link>
              </li>
            </ul>
          </div>

          <Link
            to={"/cart"}
            // className="btn btn-outline-light position-fixed end-0"
            className="btn position-fixed end-0"
            style={{
              width: "60px",
              marginRight: "12px",
              top: "8px",
              // top: "15px",
              // right: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* cart */}
            <GiShoppingBag style={{ fontSize: "2rem", color: "white" }} />
            <span
              style={{
                position: "absolute",
                marginTop: "5px",
                fontWeight: "bold",
                right: "0px",
                top: "0px",
                // color: "black",
                // backgroundColor: "rgba(255,255,255,0.8)",
                // border: "1px solid rgba(255,255,255,0.8)",
                maxWidth: "17px",
                minWidth: "17px",
                maxHeight: "17px",
                minHeight: "17px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // borderRadius: "10px",
                color: "white",
              }}
            >
              {cartCount}
            </span>
          </Link>
        </div>
      </nav>
    </>
  );
}
