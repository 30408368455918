import { IoReceiptSharp } from "react-icons/io5";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { useState } from "react";
import axios from "axios";
import config from "../config";

export default function OrderDetails() {
  const [token, setToken] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [data, setData] = useState<
    | {
        id: number;
        fullName: string;
        email: string;
        phonenumbers: string[];
        address: string;
        city: string;
        country: string;
        order: {
          product: {
            id: number;
            category: string;
            images: { src: string; alt: string }[] | null;
            title: string;
            description: string;
            sizes: string[] | null;
            styles: string[] | null;
            price: string;
            discountPercentage: string;
            createdAt: string;
            updatedAt: string;
          };
          productQuantity: string;
        }[];
        totalAmount: string;
        totalDiscount: string;
        newAmount: string;
        placedAt: string;
        status: string;
        statusUpdatedAt: string;
        courierId: string | null;
        orderToken: string;
      }
    | undefined
  >();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <div style={{ width: "100%", minHeight: "calc(100vh - 5rem)" }}>
        <Navbar></Navbar>
        <div
          style={{
            width: "100%",
            height: "100%",
            paddingBottom: "5rem",
            marginTop: "5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {orderLoaded && data ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "550",
                  fontStyle: "italic",
                  borderBottom: "1px solid black",
                  marginBottom: "1rem",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Order Details
              </div>
              <div className="container text-center">
                <div className="row">
                  <div className="col-3" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Order ID
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.id}
                    </div>
                  </div>
                  <div className="col-5" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Order Token
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.orderToken}
                    </div>
                  </div>
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      FullName
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.fullName}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Email
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.email}
                    </div>
                  </div>
                  <div className="col-6" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Phone Number
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.phonenumbers.map((_p) => (
                        <>
                          {_p}
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Address
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.address}
                    </div>
                  </div>
                  <div className="col-3" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      City
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.city}
                    </div>
                  </div>
                  <div className="col-3" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Country
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {data.country}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Order
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0,0,0,0.7)",
                          color: "white",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "20%" }}>#</div>
                        <div style={{ width: "60%" }}>Title</div>
                        <div style={{ width: "20%" }}>Quantity</div>
                      </div>
                      {data.order.map((_o, _i) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div style={{ width: "20%" }}>{_i + 1}</div>
                            <div style={{ width: "60%" }}>
                              {_o.product.title}
                            </div>
                            <div style={{ width: "20%" }}>
                              {_o.productQuantity}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Total Price
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {formatter.format(Number.parseInt(data.totalAmount))}
                    </div>
                  </div>
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Total Discount
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {formatter.format(Number.parseInt(data.totalDiscount))}
                    </div>
                  </div>
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      New Price
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {formatter.format(Number.parseInt(data.newAmount))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Order Placed At
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {`${new Date(data.placedAt).toDateString()}, ${new Date(
                        data.placedAt
                      )
                        .toTimeString()
                        .replace("(Pakistan Standard Time)", "")
                        .toString()}`}
                    </div>
                  </div>
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Status
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                        backgroundColor:
                          data.status === "received"
                            ? "orange"
                            : data.status === "dispatched"
                            ? "blue"
                            : data.status === "completed"
                            ? "green"
                            : "red",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      {data.status}
                    </div>
                  </div>
                  <div className="col-4" style={{ marginBottom: "1rem" }}>
                    <div
                      style={{
                        backgroundColor: "var(--navBarBG)",
                        color: "white",
                        fontWeight: "550",
                      }}
                    >
                      Status Updated At
                    </div>
                    <div
                      style={{
                        wordBreak: "break-all",
                        overflow: "break-word",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {`${new Date(
                        data.statusUpdatedAt
                      ).toDateString()}, ${new Date(data.statusUpdatedAt)
                        .toTimeString()
                        .replace("(Pakistan Standard Time)", "")
                        .toString()}`}
                    </div>
                  </div>
                </div>
                {data.status === "dispatched" && (
                  <div className="row">
                    <div className="col-12" style={{ marginBottom: "1rem" }}>
                      <div
                        style={{
                          backgroundColor: "var(--navBarBG)",
                          color: "white",
                          fontWeight: "550",
                        }}
                      >
                        Courier ID
                      </div>
                      <div
                        style={{
                          wordBreak: "break-all",
                          overflow: "break-word",
                          textWrap: "wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {data.courierId}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <IoReceiptSharp
                style={{ fontSize: "10rem", marginBottom: "1rem" }}
              ></IoReceiptSharp>
              <input
                type="text"
                value={token}
                onChange={(event) => {
                  setToken(event.target.value);
                  if (token.length > 0) {
                    setTokenError("");
                  }
                }}
                placeholder="order token"
                style={{
                  width: "330px",
                  padding: "10px 5px",
                  marginBottom: "3px",
                }}
              />
              <span
                style={{ height: "10px", marginBottom: "22px", color: "red" }}
              >
                {tokenError}
              </span>
              <button
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "var(--navBarBG)",
                  border: "none",
                  color: "white",
                  fontSize: "1.2rem",
                  marginBottom: "1rem",
                }}
                onClick={async (event) => {
                  event.preventDefault();
                  if (token.length < 1) {
                    setTokenError("please enter token");
                  } else {
                    setTokenError("");
                    setIsLoading(true);
                    const response = await axios.get(
                      `${config.baseUrl}orders/token/${token}`
                    );
                    if (response.data.status === 404) {
                      setToken("");
                      setTokenError("Invalid Token");
                    } else {
                      try {
                        setData(response.data);
                        setIsLoading(false);
                        setOrderLoaded(true);
                      } catch (err) {
                        setTokenError("Something went wrong");
                        console.log(err);
                      }
                    }
                    setIsLoading(false);
                  }
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border"
                    style={{
                      width: "2rem",
                      height: "2rem",
                    }}
                    role="status"
                  ></div>
                ) : (
                  "Check Order"
                )}
              </button>
              <span style={{ fontWeight: "550", fontStyle: "italic" }}>
                Note: Order token is sent to customers via email.
              </span>
            </>
          )}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
