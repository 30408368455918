import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { TiTick } from "react-icons/ti";

export default function CheckOut() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["cart"]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [orderPlaced, setOrderPlaced] = useState(false);
  useEffect(() => {
    console.log(cookies.cart);
    if (!(cookies.cart && cookies.cart.length > 0)) {
      navigate("/");
    }
  }, [cookies.cart]);
  const [data, setData] = useState<{
    fullName: string;
    email: string;
    phonenumbers: string[];
    address: string;
    city: string;
    country: string;
  }>({
    fullName: "",
    email: "",
    phonenumbers: ["", ""],
    address: "",
    city: "",
    country: "",
  });
  const [errors, setErrors] = useState<{
    fullName: string;
    email: string;
    phonenumber1: string;
    phonenumber2: string;
    address: string;
    city: string;
    country: string;
  }>({
    fullName: "",
    email: "",
    phonenumber1: "",
    phonenumber2: "",
    address: "",
    city: "",
    country: "",
  });
  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (data.fullName.length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          fullName: "please enter your full name",
        };
      });
    }
    if (data.email.length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: "please enter your email",
        };
      });
    }
    if (data.phonenumbers[0].length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          phonenumber1: "please enter your phone number",
        };
      });
    }
    if (data.address.length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          address: "please enter your address",
        };
      });
    }
    if (data.city.length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          city: "please enter your city",
        };
      });
    }
    if (data.country.length < 1) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          country: "please enter your country",
        };
      });
    }
    if (
      data.fullName.length > 0 &&
      data.email.length > 0 &&
      data.phonenumbers[0].length > 0 &&
      data.address.length > 0 &&
      data.city.length > 0 &&
      data.country.length > 0
    ) {
      setIsLoading(true);
      setLoadingMsg("Placing Order");
      let cartItems: {
        productId: number;
        productQuantity: number;
        style: string;
        size: string;
      }[] = [];
      cookies.cart.map(
        (
          item: {
            id: string;
            images: {
              src: any;
              alt: string;
            }[];
            title: string;
            description: string;
            sizes: string;
            styles: string;
            price: number;
            discountPercentage: number;
            quantity: number;
          },
          index: number
        ) => {
          cartItems.push({
            productId: Number.parseInt(item.id),
            productQuantity: item.quantity,
            style: item.styles,
            size: item.sizes,
          });
        }
      );
      const req = {
        fullName: data.fullName.trim(),
        email: data.email.trim(),
        phonenumbers:
          data.phonenumbers[1].trim().length > 0
            ? [data.phonenumbers[0].trim(), data.phonenumbers[1].trim()]
            : [data.phonenumbers[0].trim()],
        address: data.address.trim(),
        city: data.city.trim(),
        country: data.country.trim(),
        order: cartItems,
      };
      const result = await axios.post(`${config.baseUrl}orders`, req);
      setOrderPlaced(true);
      setLoadingMsg("Order Placed Successfully!");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setCookie("cart", []);
      setIsLoading(false);
      setLoadingMsg("");

      navigate("/");
    }
  };
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 0 10px black",
            padding: "1rem 0",
            marginBottom: "1rem",
          }}
        >
          <img
            src={require("../assets/img/logo.png")}
            style={{ filter: "invert()", width: "150px" }}
            alt=""
          />
        </div>
        <form className="container">
          <div className="mb-3">
            <label htmlFor="exampleInputFullName" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              value={data.fullName}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    fullName: event.target.value,
                  };
                });
              }}
              className="form-control"
              name="fullName"
              id="exampleInputFullName"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
            />
            <span style={{ color: "red" }}>{errors.fullName}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail" className="form-label">
              Email address
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="exampleInputEmail"
              aria-describedby="emailHelp"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.email}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    email: event.target.value,
                  };
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.email}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPhone1" className="form-label">
              Phone Number 1
            </label>
            <input
              type="tel"
              className="form-control"
              name="phoneNumber1"
              id="exampleInputPhone1"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.phonenumbers[0]}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    phonenumbers: [
                      event.target.value,
                      prevData.phonenumbers[1],
                    ],
                  };
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.phonenumber1}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPhone2" className="form-label">
              Phone Number 2{" "}
              <span style={{ fontStyle: "italic" }}>(optional)</span>
            </label>
            <input
              type="tel"
              className="form-control"
              name="phoneNumber2"
              id="exampleInputPhone2"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.phonenumbers[1]}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    phonenumbers: [
                      prevData.phonenumbers[0],
                      event.target.value,
                    ],
                  };
                });
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputAddress" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              name="address"
              id="exampleInputAddress"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.address}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    address: event.target.value,
                  };
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.address}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputCity" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              name="city"
              id="exampleInputCity"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.city}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    city: event.target.value,
                  };
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.city}</span>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputCountry" className="form-label">
              Country
            </label>
            <input
              type="text"
              className="form-control"
              name="country"
              id="exampleInputCountry"
              style={{
                outline: "none",
                border: "1px solid var(--navBarBG)",
                borderRadius: "0",
              }}
              value={data.country}
              onChange={(event) => {
                setData((prevData) => {
                  return {
                    ...prevData,
                    country: event.target.value,
                  };
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.country}</span>
          </div>
          <div>
            <button
              className="btn btn-primary"
              style={{
                borderRadius: "0",
                outline: "none",
                backgroundColor: "var(--navBarBG)",
                marginRight: "2rem",
              }}
              id="checkout-cancelBtn"
              onClick={(event) => {
                event.preventDefault();
                navigate("/");
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              id="checkout-orderBtn"
              style={{
                borderRadius: "0",
                outline: "none",
                backgroundColor: "var(--navBarBG)",
              }}
            >
              Place Order
            </button>
          </div>
          <div style={{ margin: "1rem 0" }}>
            <span style={{ fontWeight: "550" }}>
              <sup>*</sup>Note: Payment method is Cash-on-Delivery (COD) only.
            </span>
          </div>
        </form>
      </div>
      {isLoading && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: "0",
            backgroundColor: "rgba(0,0,0,0.8)",
            backdropFilter: "blur(4px)",
            zIndex: "99999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          {orderPlaced ? (
            <>
              <TiTick
                style={{ fontSize: "2rem", width: "2rem", height: "2rem" }}
              ></TiTick>
            </>
          ) : (
            <>
              <div
                className="spinner-border"
                style={{ width: "2rem", height: "2rem", marginBottom: "1rem" }}
                role="status"
              ></div>
            </>
          )}

          <span>{loadingMsg}</span>
        </div>
      )}
    </>
  );
}
