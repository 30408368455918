import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { IoAdd } from "react-icons/io5";
import { MdDelete, MdOutlineHorizontalRule } from "react-icons/md";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Link } from "react-router-dom";

export default function Cart() {
  const [cookies, setCookie] = useCookies(["cart"]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PKR",
    maximumFractionDigits: 0,
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  useEffect(() => {
    const calculateTotalPrice = () => {
      if (!cookies.cart || cookies.cart.length === 0) {
        setTotalPrice(0);
        setTotalDiscount(0);
        setDiscountedPrice(0);
        return;
      }

      const tempTotalPrice = cookies.cart.reduce((acc: any, item: any) => {
        const tempPrice = item.price;
        return acc + tempPrice * item.quantity;
      }, 0);
      const tempTotalDiscount = cookies.cart.reduce((acc: any, item: any) => {
        const tempDiscount = Math.round(
          (item.price * item.discountPercentage) / 100
        );
        return acc + tempDiscount * item.quantity;
      }, 0);
      const tempTotalDiscountedPrice = cookies.cart.reduce(
        (acc: any, item: any) => {
          const tempDiscountedPrice = Math.round(
            item.price - (item.price * item.discountPercentage) / 100
          );
          return acc + tempDiscountedPrice * item.quantity;
        },
        0
      );

      setTotalPrice(tempTotalPrice);
      setTotalDiscount(tempTotalDiscount);
      setDiscountedPrice(tempTotalDiscountedPrice);
    };

    calculateTotalPrice();
  }, [cookies.cart]);
  const handleClearCart = () => {
    setCookie("cart", []);
    setTotalPrice(0);
    setTotalDiscount(0);
    setDiscountedPrice(0);
  };
  return (
    <>
      <Navbar></Navbar>
      {cookies.cart && cookies.cart.length > 0 ? (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "4rem",
            }}
          >
            {cookies.cart.map(
              (
                item: {
                  id: string;
                  images: {
                    src: any;
                    alt: string;
                  }[];
                  title: string;
                  description: string;
                  sizes: string[];
                  styles: string[];
                  price: number;
                  discountPercentage: number;
                  quantity: number;
                },
                index: number
              ) => (
                <div
                  className="cart-CardContainer"
                  key={index}
                  style={{
                    margin: "1rem 0",
                    display: "flex",
                    alignItems: "center",
                    padding: "1rem 3rem",
                    border: "1px solid black",
                  }}
                >
                  <div style={{ display: "flex" }} className="cart-CardTopPane">
                    <img
                      src={item.images[0].src}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <div
                      style={{ margin: "0 1rem" }}
                      className="cart-cardQuantityContainer"
                    >
                      <div className="selectQuantity-Container">
                        <span style={{ fontWeight: "bold" }}>{item.title}</span>
                        <div className="selectQuantity-Box">
                          <div
                            className="selectQuantity-SubtractBtn"
                            onClick={() => {
                              let temp: {
                                id: string;
                                images: {
                                  src: any;
                                  alt: string;
                                }[];
                                title: string;
                                description: string;
                                sizes: string[];
                                styles: string[];
                                price: number;
                                discountPercentage: number;
                                quantity: number;
                              }[] = cookies.cart;
                              if (temp[index].quantity > 1) {
                                temp[index].quantity -= 1;
                                setCookie("cart", temp);
                              }
                            }}
                          >
                            <MdOutlineHorizontalRule />
                          </div>
                          <div className="selectQuantity-text">
                            {item.quantity}
                          </div>
                          <div
                            className="selectQuantity-AddBtn"
                            onClick={() => {
                              let temp: {
                                id: string;
                                images: {
                                  src: any;
                                  alt: string;
                                }[];
                                title: string;
                                description: string;
                                sizes: string[];
                                styles: string[];
                                price: number;
                                discountPercentage: number;
                                quantity: number;
                              }[] = cookies.cart;
                              temp[index].quantity += 1;
                              setCookie("cart", temp);
                            }}
                          >
                            <IoAdd />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    className="cart-CardBottomPane"
                  >
                    <div
                      className="cart-CardPriceContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 1rem",
                      }}
                    >
                      <span
                        className="cart-CardPrice-heading"
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "500",
                          color: "rgba(0,0,0,0.8)",
                        }}
                      >
                        Per Item Price
                      </span>
                      <span style={{ color: "gray" }}>
                        {item.discountPercentage > 0 ? (
                          <span
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ textDecoration: "line-through" }}>
                              {formatter.format(item.price)}
                            </span>
                            <span
                              className="cart-CardPrice-price"
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                marginTop: "-10px",
                              }}
                            >
                              {formatter.format(
                                Math.round(
                                  item.price -
                                    (item.price * item.discountPercentage) / 100
                                )
                              )}
                            </span>
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            {formatter.format(item.price)}
                          </span>
                        )}
                      </span>
                    </div>

                    <button
                      className="cart-CardDeleteBtn"
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "2rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 5px",
                        margin: "0 1rem",
                      }}
                      onClick={() => {
                        let temp: {
                          id: string;
                          images: {
                            src: any;
                            alt: string;
                          }[];
                          title: string;
                          description: string;
                          sizes: string[];
                          styles: string[];
                          price: number;
                          discountPercentage: number;
                        }[] = cookies.cart;
                        temp.splice(index, 1);
                        setCookie("cart", temp);
                      }}
                    >
                      <MdDelete></MdDelete>
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
          <div
            style={{
              height: "150px",
              boxShadow: "0 0 10px black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="cart-PriceContainer">
                <span className="cart-PriceContainer-title">Total Price</span>
                <span className="cart-PriceContainer-price">{totalPrice}</span>
              </div>
              <div className="cart-PriceContainer">
                <span className="cart-PriceContainer-title">
                  Total Discount
                </span>
                <span className="cart-PriceContainer-price">
                  {totalDiscount}
                </span>
              </div>
              <div className="cart-PriceContainer">
                <span className="cart-PriceContainer-title">New Price</span>
                <span className="cart-PriceContainer-price">
                  {discountedPrice}
                </span>
              </div>
            </div>
            <div
              style={{
                width: "calc(100% + 32px)",
                marginTop: "1rem",
                boxShadow: "0 0 10px black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                }}
              >
                <button
                  className="cart-bottomButtons"
                  onClick={() => handleClearCart()}
                >
                  Clear
                </button>
                <Link
                  to={"/checkout"}
                  className="cart-bottomButtons"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Checkout
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 id="notFound-Icon" style={{ fontSize: "6rem" }}>
            😔
          </h1>
          <p style={{ fontSize: "3rem", textAlign: "center" }}>
            No items in Cart
          </p>
          <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
            Come back after adding items in cart.
          </p>
          <Link
            to={"/"}
            className="cart-bottomButtons"
            style={{
              width: "200px",
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Continue Shopping
          </Link>
        </div>
      )}
      <Footer></Footer>
    </>
  );
}
