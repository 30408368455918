import { useEffect } from "react";
import CategoriesCard from "../components/categoriesCard";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function Home() {
  return (
    <>
      <Navbar></Navbar>
      <section
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ maxHeight: "100vh", backgroundColor: "black" }}
      >
        <div className="carousel-inner" style={{ height: "100vh" }}>
          <div className="carousel-item active">
            <img
              src={require("../assets/img/RottenAttire.png")}
              className="d-block w-100"
              alt="..."
              id="homeTopImage"
              data-aos="zoom-in"
              style={{
                objectFit: "contain",
                // objectPosition: "0 10px",
                height: "100vh",
                margin: "0 auto",
                // backgroundImage: require("../assets/img/darkBG.png"),
                // backgroundImage: "url(../assets/img/darkBG.png)",
                // backgroundColor: "red",
                opacity: "0.9",
              }}
            />
            {/* <div
              className="carousel-caption d-block"
              style={{ paddingBottom: "4rem" }}
            >
              <h5 className="slideShow-text-heading" data-aos="zoom-in">
                Elevate Your Style
              </h5>
              <p className="slideShow-text-content" data-aos="fade-up">
                Discover the Latest Trends and Timeless Classics in Our
                Collection.
              </p>
            </div> */}
          </div>
        </div>
        {/* <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button> */}
      </section>
      {/* <section
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ maxHeight: "100vh", backgroundColor: "black" }}
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner" style={{ height: "100vh" }}>
          <div className="carousel-item active">
            <img
              src={require("../assets/img/slideshow/1.jpg")}
              className="d-block w-100"
              alt="..."
              style={{
                objectFit: "cover",
                objectPosition: "0 10px",
                height: "100vh",
                opacity: "0.5",
              }}
            />
            <div
              className="carousel-caption d-block"
              style={{ paddingBottom: "4rem" }}
            >
              <h5 className="slideShow-text-heading" data-aos="zoom-in">
                Elevate Your Style
              </h5>
              <p className="slideShow-text-content" data-aos="fade-up">
                Discover the Latest Trends and Timeless Classics in Our
                Collection.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={require("../assets/img/slideshow/2.jpg")}
              className="d-block w-100"
              alt="..."
              style={{
                objectFit: "cover",
                objectPosition: "0 10px",
                height: "100vh",
                opacity: "0.5",
              }}
            />
            <div
              className="carousel-caption d-block"
              style={{ paddingBottom: "4rem" }}
            >
              <h5 className="slideShow-text-heading" data-aos="zoom-in">
                Unleash Your Inner Fashionista
              </h5>
              <p className="slideShow-text-content" data-aos="fade-up">
                Exclusive Designs and Accessories Await You!
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={require("../assets/img/slideshow/3.jpg")}
              className="d-block w-100"
              alt="..."
              style={{
                objectFit: "cover",
                objectPosition: "0 10px",
                height: "100vh",
                opacity: "0.5",
              }}
            />
            <div
              className="carousel-caption d-block"
              style={{ paddingBottom: "4rem" }}
            >
              <h5 className="slideShow-text-heading" data-aos="zoom-in">
                Shop with Confidence
              </h5>
              <p className="slideShow-text-content" data-aos="fade-up">
                Premium Quality and Unmatched Elegance at Your Fingertips.
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </section> */}
      <section id="services" className="text-center" style={{ width: "100%" }}>
        <div
          style={{
            margin: "20px",
            width: "100%",
          }}
        >
          <div className="row">
            <div
              className="col-12 section-intro text-center"
              data-aos="fade-up"
            >
              <h1>Categories</h1>
              <div className="divider"></div>
              <p>
                Checkout our various categories of clothing and accessories in
                our collection
              </p>
            </div>
          </div>
          <div
            style={{
              overflow: "auto",
              margin: "0",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              justifyItems: "center",
              gap: "6px",
              padding: "6px",
            }}
          >
            <div data-aos="zoom-in">
              <CategoriesCard
                img="tshirts"
                text="T-Shirts"
                link="/productCategories/t-shirts"
              ></CategoriesCard>
            </div>
            <div data-aos="zoom-in">
              <CategoriesCard
                img="shirts"
                text="Shirts"
                link="/productCategories/shirts"
              ></CategoriesCard>
            </div>
            <div data-aos="zoom-in">
              <CategoriesCard
                img="shorts"
                text="Shorts"
                link="/productCategories/shorts"
              ></CategoriesCard>
            </div>
            <div data-aos="zoom-in">
              <CategoriesCard
                img="accessories"
                text="Accessories"
                link="/productCategories/accessories"
              ></CategoriesCard>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}
