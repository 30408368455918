import { Link } from "react-router-dom";

interface props {
  img: string;
  text: string;
  link: string;
}
export default function CategoriesCard({ img, text, link }: props) {
  return (
    <div className="categoriesCard-Container">
      <img
        // src={require("../assets/img/categories/men.jpg")}
        src={require(`../assets/img/categories/${img}.jpg`)}
        alt="menCategoryImage"
        className="categoriesCard-Image"
      />
      <Link
        className="categoriesCard-text"
        to={link}
        style={{
          fontWeight: "500",
          filter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.5))",
        }}
      >
        {text}
      </Link>
      {/* <a className="categoriesCard-text" href="#">
        {text}
      </a> */}
    </div>
  );
}
