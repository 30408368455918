import { useEffect, useState } from "react";
import "./App.css";
import AOS from "aos";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import NotFound from "./pages/notFound";
import ProductCategories from "./pages/productCategories";
import ProductDetails from "./pages/productDetails";
import Cart from "./pages/cart";
import CheckOut from "./pages/checkOut";
import OrderDetails from "./pages/orderDetails";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/productCategories/:category"
          element={<ProductCategories />}
        />
        <Route path="/productDetails/:id" element={<ProductDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/orderDetails" element={<OrderDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
