import { useEffect, useState } from "react";
import { Router, useParams, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Card from "../components/card";
import Footer from "../components/footer";
import axios from "axios";
import config from "../config";

export default function ProductCategories() {
  const { category } = useParams();
  // {
  //   images: [
  //     { src: require("./../assets/img/dummy/DSC03846.JPG"), alt: "img1" },
  //     { src: require("./../assets/img/dummy/DSC03848.JPG"), alt: "img1" },
  //     { src: require("./../assets/img/dummy/DSC03920.JPG"), alt: "img1" },
  //   ],
  //   title: "Bracelet",
  //   description: "Lorem ipsum...",
  //   sizes: ["XS", "S", "M", "L", "XL", "XXL"],
  //   styles: ["Slim Fit", "Regular Fit"],
  //   price: 1000,
  //   discountPercentage: 20,
  // }
  const [data, setData] = useState<
    | {
        id: number;
        images: { src: string; alt: string }[];
        title: string;
        description: string;
        sizes: string[];
        styles: string[];
        price: number;
        discountPercentage: number;
        category: string;
      }[]
    | undefined
  >();
  const fetchProducts = async () => {
    const res = await axios.get(
      `${config.baseUrl}product/category/${category}`
    );
    setData(res.data);
    if (category === "men" || category === "women") {
      const res2 = await axios.get(`${config.baseUrl}product/category/unisex`);
      setData((prevData) => {
        return prevData?.concat(res2.data);
      });
    }
  };
  useEffect(() => {
    fetchProducts();
    // console.log("A");
  }, [category]);
  return (
    <>
      {category === "t-shirts" ||
      category === "shirts" ||
      category === "accessories" ||
      category === "shorts" ? (
        // {category === "men" ||
        // category === "women" ||
        // category === "accessories" ||
        // category === "unisex" ? (
        <>
          <Navbar></Navbar>
          <div
            className="container d-flex flex-column align-items-center justify-content-center"
            style={{
              //   minHeight: "100vh",
              marginTop: "5rem",
              //   marginBottom: "1rem",
            }}
          >
            <h1>
              {category && category === "t-shirts"
                ? "T-Shirts"
                : category?.charAt(0).toUpperCase() + category?.slice(1)}{" "}
              Collection
            </h1>
            <div
              style={{ backgroundColor: "black", height: "2px", width: "100%" }}
            ></div>
            <div
              className="pt-5"
              style={{
                overflow: "auto",
                margin: "0",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                justifyItems: "center",
                gap: "16px",
                padding: "16px",
              }}
              data-aos="zoom-in"
            >
              {data && data.length > 0 ? (
                data.map((data, index) => (
                  <Card
                    data={data}
                    // key={index}
                    // img={data.images[0]}
                    // title={data.title}
                    // description={data.description}
                    // price={data.price}
                    // discount={data.discountPercentage}
                  ></Card>
                ))
              ) : (
                <div
                  style={{
                    height: "80vh",
                    fontSize: "1.5rem",
                    color: "gray",
                  }}
                >
                  No Products Found
                </div>
              )}
            </div>
          </div>
          <Footer></Footer>
        </>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 id="notFound-Icon" style={{ fontSize: "6rem" }}>
            😔
          </h1>
          <h1>Collection not Found</h1>
        </div>
      )}
    </>
  );
}
