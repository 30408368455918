import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 id="notFound-Icon" style={{ fontSize: "6rem" }}>
          😔
        </h1>
        <p style={{ fontSize: "3rem", textAlign: "center" }}>Page not Found</p>
        <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
          Incorrect url is causing this problem.
        </p>
        <Link
          to={"/"}
          className="cart-bottomButtons"
          style={{
            width: "200px",
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Go To HomePage
        </Link>
      </div>
    </>
  );
}
