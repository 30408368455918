import axios from "axios";
import { useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import config from "../config";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    fullName: string;
    email: string;
    subject: string;
    message: string;
  }>({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errrors, setErrors] = useState<{
    fullName: string;
    email: string;
    subject: string;
    message: string;
  }>({ fullName: "", email: "", subject: "", message: "" });
  return (
    <div style={{ height: "100vh" }}>
      <section
        id="contact"
        className="bg-cover text-white"
        style={{
          backgroundColor: "var(--navBarBG)",
          minHeight: "93%",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div
              className="col-12 section-intro text-center"
              data-aos="fade-up"
            >
              <h1>Get in touch</h1>
              <div className="divider"></div>
              <p>
                For any query you can contact us. Write following details and
                click on send message button.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto" data-aos="fade-up">
              <form action="#" className="row g-1">
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="form-control footer-inputField"
                    placeholder="Full name"
                    value={data.fullName}
                    onChange={(event) => {
                      setData((prevData) => {
                        return {
                          ...prevData,
                          fullName: event.target.value,
                        };
                      });
                    }}
                  />
                  <div
                    style={{
                      color: "red",
                      minHeight: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={
                        errrors.fullName.length < 1
                          ? {
                              marginTop: "-10px",
                              transition: "all 0.1s ease-in",
                            }
                          : { marginTop: "0", transition: "all 0.1s ease-in" }
                      }
                    >
                      {errrors.fullName}
                    </span>
                  </div>
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    type="email"
                    className="form-control  footer-inputField"
                    placeholder="Email address"
                    value={data.email}
                    onChange={(event) => {
                      setData((prevData) => {
                        return {
                          ...prevData,
                          email: event.target.value,
                        };
                      });
                    }}
                  />
                  <div
                    style={{
                      color: "red",
                      minHeight: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={
                        errrors.email.length < 1
                          ? {
                              marginTop: "-10px",
                              transition: "all 0.1s ease-in",
                            }
                          : { marginTop: "0", transition: "all 0.1s ease-in" }
                      }
                    >
                      {errrors.email}
                    </span>
                  </div>
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="form-control  footer-inputField"
                    placeholder="Subject"
                    value={data.subject}
                    onChange={(event) => {
                      setData((prevData) => {
                        return {
                          ...prevData,
                          subject: event.target.value,
                        };
                      });
                    }}
                  />
                  <div
                    style={{
                      color: "red",
                      minHeight: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={
                        errrors.subject.length < 1
                          ? {
                              marginTop: "-10px",
                              transition: "all 0.1s ease-in",
                            }
                          : { marginTop: "0", transition: "all 0.1s ease-in" }
                      }
                    >
                      {errrors.subject}
                    </span>
                  </div>
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <textarea
                    value={data.message}
                    onChange={(event) => {
                      setData((prevData) => {
                        return {
                          ...prevData,
                          message: event.target.value,
                        };
                      });
                    }}
                    name=""
                    id=""
                    cols={30}
                    rows={4}
                    className="form-control footer-inputField"
                    placeholder="Message"
                  ></textarea>
                  <div
                    style={{
                      color: "red",
                      minHeight: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={
                        errrors.message.length < 1
                          ? {
                              marginTop: "-10px",
                              transition: "all 0.1s ease-in",
                            }
                          : { marginTop: "0", transition: "all 0.1s ease-in" }
                      }
                    >
                      {errrors.message}
                    </span>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-main"
                    type="submit"
                    id="footer-sendMessageBtn"
                    onClick={async (event) => {
                      event.preventDefault();
                      if (data.fullName.length < 1) {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            fullName: "please enter your fullname",
                          };
                        });
                      } else {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            fullName: "",
                          };
                        });
                      }
                      if (data.email.length < 1) {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            email: "please enter your email",
                          };
                        });
                      } else {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            email: "",
                          };
                        });
                      }
                      if (data.subject.length < 1) {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            subject: "please enter your subject",
                          };
                        });
                      } else {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            subject: "",
                          };
                        });
                      }
                      if (data.message.length < 1) {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            message: "please enter your message",
                          };
                        });
                      } else {
                        setErrors((prevErr) => {
                          return {
                            ...prevErr,
                            message: "",
                          };
                        });
                      }
                      if (
                        data.fullName.length > 0 &&
                        data.email.length > 0 &&
                        data.subject.length > 0 &&
                        data.message.length > 0
                      ) {
                        setLoading(true);
                        const result = await axios.post(
                          `${config.baseUrl}messages`,
                          {
                            fullName: data.fullName,
                            email: data.email,
                            subject: data.subject,
                            message: data.message,
                          }
                        );
                        setLoading(false);
                        window.location.reload();
                      }
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "1rem",
                      width: "150px",
                      height: "40px",
                    }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border"
                        style={{
                          width: "2rem",
                          height: "2rem",
                        }}
                        role="status"
                      ></div>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer
        className="footerText-Container"
        style={{ minHeight: "7%", display: "flex", alignItems: "center" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="footerText-Bottom">
                © RottinAttire {new Date().getFullYear()}, Designed By Awais
                Anwar
              </p>
            </div>
            <div className="col-md-6 text-md-end">
              <div
                className="footerLinks-Container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <a href="https://www.facebook.com/profile.php?id=61564226665644" className="footerLinks">
                  <FaFacebook></FaFacebook>
                </a>
		{/* <a href="#" className="footerLinks">
                  <FaTwitter></FaTwitter>
                </a> */}
                <a href="https://www.instagram.com/rottenattire/" className="footerLinks">
                  <FaInstagram></FaInstagram>
                </a>
                {/* <a href="#" className="footerLinks">
                  <FaYoutube></FaYoutube>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
